.Home__outerContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
}

.Home__innerContainer {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Home__logo {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.Home__socialMediaIcons {
    display: flex;
    justify-content: right;
    flex-direction: row;
}