.App__container {
  height: 100%;
  text-align: center;
  color: white
}

.DesktopLinkList__container {
  position: absolute;
  margin-left: calc(15% - 125px);
  margin-top: 3%;
  width: 70%;
  padding: 5px 125px;
  border-radius: 10px;
  display: flex;
  color: white;
  justify-content: space-between;
  list-style-type: none;
  font-family: 'Impact';
  font-size: 20pt;
  background-color: #505050;
}



.MobileLinkList__container {
  position: absolute;
  margin-left: 5%;
  text-align: left;
  margin-top: 13%;
  width: 90%;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
  list-style-type: none;
  font-family: 'Impact';
  font-size: 20pt;
}

.MobileLinkListHidden__container {
  display: none;
}

.LinkList__container > li > a {
  color: white;
  text-decoration: none;
}

.LinkList__container > li > a:hover {
  color: #A6B3B3;
  text-decoration: none;
}

.App__menuIcon {
  height: 30px;
  margin-left: 5%;
  margin-top: 5%;
  display: flex;
  position: absolute;
}

.App__menuIconHidden {
  display: none;
}


.Nav__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
